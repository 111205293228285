import { Box, Container, Heading, useColorMode, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ImgTextModule from "../components/ImgTextModule";
import IntroComponent2colMonochrome from "../components/IntroComponent2colMonochrome";
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation";

export default function InvestorRelations() {
  const { setColorMode } = useColorMode();
  useEffect(() => {
    setColorMode("light");
  });
  return (
    <>
      <Helmet>
        <title>tokentus | Disclaimer</title>
      </Helmet>
      <ImgTextModule
        src="target.mp4"
        delay={0.3}
        duration={0.5}
        threshold={0.3}
        initialY={10}
        initialX={10}
        video={true}
        h="55vh"
        mediaOpacity={0.9}
        bgColor="brand.black"
      >
        <Container variant="layoutContainer" mt={8}>
          <Box p={{ base: 0, lg: "8" }} py={8}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text
                color="brand.white"
                fontWeight="normal"
                fontSize={{ base: "5xl", lg: "6xl" }}
              >
                Disclaimer.
              </Text>
            </FadeInAnimation>
          </Box>
        </Container>
      </ImgTextModule>

      <IntroComponent2colMonochrome
        version="thirds"
        headline="Legal Information."
        text1="Information and transparency for you."
        text2=" Information in accordance with § 5 TMG, (German Media Laws)"
      />

      <Container
        variant={"layoutContainer"}
        px={{ base: 0, lg: "8" }}
        w={{ base: "100%", lg: "50%" }}
        ml={{ lg: "50%" }}
      >
        <Heading
          fontSize="4xl"
          fontWeight="normal"
          mb={4}
          color="brand.meddarkgray"
        >
          tokentus investment AG
        </Heading>
        <Text
          w={{ base: "50%", lg: "50%" }}
          pt={2}
          color="brand.darkmedgray"
          fontWeight="normal"
          fontSize="lg"
        >
          <br />
          Taunusanlage 8 c/o WeWork
          <br />
          60329 Frankfurt a. Main
          <br />
          <br />
          Handelsregister: HRB 117369
          <br />
          Registergericht: Amtsgericht Frankfurt (Main)
          <br />
          <br />
          Vertreten durch:
          <br />
          Oliver Michel (Vorstand)
          <br />
          <br />
          Vorsitzender des Aufsichtsrats:
          <br />
          Michael Kollmann
          <br />
          <br />
          Kontakt:
          <br />
          <br />
          {/* Telefon: +49 175 7222 351 */}
          <br />
          email: contact@tokentus.com
          <br />
          <br />
          Umsatzsteuer-ID <br />
          Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz:{" "}
          <br /> 047 246 55178 <br /> <br />
          Verantwortlich für den Inhalt <br />
          nach §55 Abs. 2 RStV <br />
          Oliver Michel <br />
          <br />
          <br />
          Verbraucher­streit­beilegung/ <br />
          Universal­schlichtungs­stelle <br /> <br /> Wir sind nicht bereit oder
          verpflichtet, an Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle teilzunehmen.
        </Text>

        <Heading
          fontSize="2xl"
          fontWeight="normal"
          mb={4}
          mt={12}
          color="brand.meddarkgray"
        >
          Haftung für Inhalte
        </Heading>
        <Text
          w={{ base: "80%", lg: "50%" }}
          pt={2}
          mb={14}
          mt={12}
          color="brand.darkmedgray"
          fontWeight="normal"
          fontSize="sm"
        >
          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
          auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
          §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
          verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
          überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
          Tätigkeit hinweisen.
          <br />
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
          Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
          Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
          Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
          von entsprechenden Rechtsverletzungen werden wir diese Inhalte
          umgehend entfernen.
          <br />
          <Heading
            fontSize="xl"
            fontWeight="normal"
            mb={4}
            mt={8}
            color="brand.medgray"
          >
            Haftung für Links
          </Heading>
          <br />
          Unser Angebot enthält Links zu externen Websites Dritter, auf deren
          Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
          fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
          verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
          Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
          Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
          Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
          <br />
          Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
          ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
          Bekanntwerden von Rechtsverletzungen werden wir derartige Links
          umgehend entfernen.
          <Heading
            fontSize="xl"
            fontWeight="normal"
            mb={4}
            mt={8}
            color="brand.medgray"
          >
            Urheberrecht
          </Heading>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
          Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
          Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
          Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
          jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
          sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
          <br />
          <br></br>
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
          wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
          Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
          eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
          werden wir derartige Inhalte umgehend entfernen.
        </Text>
      </Container>
      <Container
        variant={"layoutContainer"}
        px={{ base: 0, lg: "8" }}
        w={{ base: "100%", lg: "50%" }}
        ml={{ lg: "50%" }}
      >
        <Heading
          fontSize="4xl"
          fontWeight="normal"
          mb={4}
          color="brand.meddarkgray"
        >
          tokentus investment AG
        </Heading>
        <Text
          w={{ base: "50%", lg: "50%" }}
          pt={2}
          color="brand.darkmedgray"
          fontWeight="normal"
          fontSize="lg"
        >
          <br />
          Taunusanlage 8 c/o WeWork
          <br />
          60329 Frankfurt a. Main
          <br />
          <br />
          Commercial register: HRB 117369
          <br />
          Registry court: Amtsgericht Frankfurt (Main)
          <br />
          <br />
          Represented by:
          <br />
          Oliver Michel (Vorstand)
          <br />
          <br />
          Chairman of the supervisory board:
          <br />
          Michael Kollmann
          <br />
          <br />
          Contact
          <br />
          <br />
          {/* Telefon: +49 177 24 21 383 */}
          <br /> <br />
          email: contact@tokentus.com
          <br /> <br />
          Sales tax ID Sales tax identification number according to § 27 a sales
          tax law: <br />
          047 246 55178 <br /> <br />
          Responsible for the content according to § 55 paragraph 2 RStV <br />
          Oliver Michel <br /> <br />
          Consumer Dispute settlement / universal arbitration board: <br /> We
          are not willing or obliged to participate in dispute settlement
          procedures before a consumer arbitration board.
        </Text>

        <Heading
          fontSize="2xl"
          fontWeight="normal"
          mb={4}
          mt={12}
          color="brand.meddarkgray"
        >
          Liability for content
        </Heading>
        <Text
          w={{ base: "80%", lg: "50%" }}
          pt={2}
          mb={14}
          mt={12}
          color="brand.darkmedgray"
          fontWeight="normal"
          fontSize="sm"
        >
          As a service provider, we are responsible for our own content on these
          pages according to Section 7, Paragraph 1 of the German Telemedia Act
          (TMG). According to §§ 8 to 10 TMG, however, we as a service provider
          are not obliged to monitor transmitted or stored third-party
          information or to investigate circumstances that indicate illegal
          activity.
          <br />
          Obligations to remove or block the use of information according to
          general laws remain unaffected. However, liability in this regard is
          only possible from the point in time at which knowledge of a specific
          infringement of the law is known. As soon as we become aware of any
          violations of the law, we will remove this content immediately.
          <br />
          <Heading
            fontSize="xl"
            fontWeight="normal"
            mb={4}
            mt={8}
            color="brand.medgray"
          >
            Liability for links
          </Heading>
          <br />
          Our offer contains links to external websites over which we have no
          influence. Therefore we cannot assume any liability for this external
          content. The respective provider or operator of the pages is always
          responsible for the content of the linked pages. The linked pages were
          checked for possible legal violations at the time of linking. Illegal
          content was not recognizable at the time of linking.
          <br />
          However, a permanent control of the content of the linked pages is not
          reasonable without concrete evidence of an infringement. As soon as we
          become aware of legal violations, we will remove such links
          immediately.
          <Heading
            fontSize="xl"
            fontWeight="normal"
            mb={4}
            mt={8}
            color="brand.medgray"
          >
            Copyright
          </Heading>
          The content and works on these pages created by the site operators are
          subject to German copyright law. The duplication, editing,
          distribution and any kind of exploitation outside the limits of
          copyright require the written consent of the respective author or
          creator. Downloads and copies of this site are only permitted for
          private, non-commercial use.
          <br /> <br />
          Insofar as the content on this site was not created by the operator,
          the copyrights of third parties are observed. In particular contents
          of third parties are marked as such. Should you nevertheless become
          aware of a copyright infringement, we ask that you inform us
          accordingly. As soon as we become aware of legal violations, we will
          remove such content immediately.
        </Text>
      </Container>
    </>
  );
}
